// 322685149526-pk39uc3kamm9ucpbiiqo460mt40a4rpc.apps.googleusercontent.com
// 4nqf3FCu9QNl6N5u-sCOw0v1
// TODO ^^^^ LOLOLOL what is this?!?!?! ^^^^
/**
@param {Object} googleUser
*/
function onSignIn(googleUser) {
  // console.log('on Sign In');
  const profile = googleUser.getBasicProfile();
  // console.log(`ID: ${ profile.getId()}`); // Do not send to your backend! Use an ID token instead.
  // console.log(`Name: ${ profile.getName()}`);
  // console.log(`Image URL: ${ profile.getImageUrl()}`);
  // console.log(`Email: ${ profile.getEmail()}`); // This is null if the 'email' scope is not present.

  const userToken = googleUser.getAuthResponse().id_token;

  const xmlhttp = new XMLHttpRequest();
  xmlhttp.open('POST', '/auth/ggllogin', true);
  xmlhttp.onreadystatechange=function() {
    if (xmlhttp.readyState == 4 && xmlhttp.status === 200) {
      const jsonObj = JSON.parse(xmlhttp.response);
      const searchParams = getSearchParameters();
      shellObj.setCookie('userToken', jsonObj.token);
      shellObj.setCookie('alias', jsonObj.alias);
      shellObj.setCookie('googleTkn', jsonObj.gglTkn);
      shellObj.setCookie('googlePicURL', profile.getImageUrl());
      sessionStorage.setItem('profilePic', profile.getImageUrl());
      location.href = searchParams.redirect?searchParams.redirect:'/';
    } else if (xmlhttp.readyState === 4) {
      showErrorDialog({heading: TEXT_ENUMS.header_funnyBad1, message: TEXT_ENUMS.error_generic1}, () => undefined);
    }
  };
  xmlhttp.send(`user_email=${ profile.getEmail() }&gglTkn=${ userToken }&name=${ profile.getName() }&googlePicURL=${ profile.getImageUrl()}` );
}
window.onSignIn = onSignIn;

/**
TODO look into google docs to figure out what this is doing haha
TL;DR logs out of oauth google
@param {function} cback
*/
function logOutOfGoogle(cback=() => undefined) {
  const apiCBack = function() {
    gapi.auth2.init().then(() => {
      const auth2 = gapi.auth2.getAuthInstance();
      auth2.signOut().then(function() {
        // console.log('User signed out.');
        cback();
      }).catch(error => {
        cback();
      });
    }).catch(error => {
      cback();
    }); ;
  };
  gapi.load('auth2', apiCBack);
  // .catch(err => {
  //   cback();
  // }); ;
}
window.logOutOfGoogle = logOutOfGoogle;

window.addEventListener('load', function load(event) {
  /**
   * The Sign-In client object.
   */
  // var auth2;

  /**
   * Initializes the Sign-In client.
   */
  const initClient = function() {
    gapi.load('auth2', function() {
      /**
           * Retrieve the singleton for the GoogleAuth library and set up the
           * client.
           */
      auth2 = gapi.auth2.init({
        client_id: '322685149526-pk39uc3kamm9ucpbiiqo460mt40a4rpc.apps.googleusercontent.com',
        context: 'signup',
        // client_id: 'CLIENT_ID.apps.googleusercontent.com'
      });
      // console.log(auth2);

      // Attach the click handler to the sign-in button
      auth2.attachClickHandler('signin-button', {}, onSuccess, onFailure);
    });
  };

  /**
   * Handle successful sign-ins.
   @param {Object} user
   */
  const onSuccess = function(user) {
    // console.log('On Success');
    // console.log(`ID: ${ profile.getId()}`); // Do not send to your backend! Use an ID token instead.
    // console.log(`Name: ${ profile.getName()}`);
    // console.log(`Image URL: ${ profile.getImageUrl()}`);
    // console.log(`Email: ${ profile.getEmail()}`); // This is null if the 'email' scope is not present.
    if (typeof shellObj.getCookie('googleTkn') === 'undefined') {
      console.error('We need to sign out!');
    }
  };

  /**
   * Handle sign-in failures.
   @param {string} error
   */
  const onFailure = function(error) {
    // console.log('Not signed in');
    console.error(error);
  };
});

/**
TODO eval if still used
@return {object}
*/
function getSearchParameters() {
  const prmstr = window.location.search.substr(1);
  return prmstr !== null && prmstr != '' ? transformToAssocArray(prmstr) : {};
}
